<template lang="pug">
#vb-start.step-component
  h2 Let’s verify<br>your Kit Box ID
  icon(data="~@icon/kit-box.svg")
  button.button(@click="goNext()") Continue
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {
    goNext,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function goNext() {
  this.$emit('complete')
}
</script>
